import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import CustomersTable from "../components/tables/AdminCustomersTable";
import RequireAuth from "./AuthRouter";
import Container from "./Container";
import LoginPage from "../pages/LoginPage";
import NotFoundPage from "../pages/NotFoundPage";
import Dashboard from "../components/Dashboard";
import MyProfilePage from "../pages/MyProfilePage";
import SupportTable from "../components/tables/SupportTable";
import DocsTable from "../components/tables/DocsTable";
import DetailsTable from "../components/tables/DetailsTable";
import UsersTable from "../components/tables/UsersTable";
import AuditsTable from "../components/tables/AuditsTable";
import AdminCustomersTable from "../components/tables/AdminCustomersTable";
import LGPDPage from "../pages/LGPDPage";
import AdminQueriesTable from "../components/tables/AdminQueriesTable";
import AdminCustomerForm from "../components/forms/AdminCustomerForm";
import ReportSimplifedTable from "../components/tables/ReportSimplifedTable";
import ReportDetailedTable from "../components/tables/ReportDetailedTable";
import InvoicesTable from "../components/tables/InvoicesTable";
import QueriesConsultPositivoTable from "../components/tables/QueriesConsultPositivoTable";
import QueriesEHMTable from "../components/tables/QueriesEHMTable";
import AdminReportDetailedQueriesTable from "../components/tables/AdminReportDetailedQueriesTable";
import AdminPaymentsTable from "../components/tables/AdminPaymentsTable";
import QueriesEHM2Table from "../components/tables/QueriesEHM2Table";
import QueriesConsultPositivoTable2 from "../components/tables/QueriesConsultPositivoTable2";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        <Route
          path="/"
          element={
            <RequireAuth>
              <Container>
                <Outlet />
              </Container>
            </RequireAuth>
          }
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<UsersTable />} />
          <Route path="queries/details" element={<DetailsTable />} />
          <Route path="queries/credits" element={<QueriesConsultPositivoTable />} />
          <Route path="queries/credits-3" element={<QueriesEHM2Table />} />
          <Route path="queries/credits-4" element={<QueriesConsultPositivoTable2 />} />
          <Route
            path="queries/cadastrals"
            element={<QueriesEHMTable />}
          />
          <Route path="reports/simplified" element={<ReportSimplifedTable />} />
          <Route path="reports/detailed" element={<ReportDetailedTable />} />
          <Route path="invoices" element={<InvoicesTable />} />
          <Route path="audits" element={<AuditsTable />} />
          <Route path="admin/queries" element={<AdminQueriesTable />} />
          <Route
            path="admin/queries-history"
            element={<AdminReportDetailedQueriesTable />}
          />
          <Route path="admin/customers" element={<AdminCustomersTable />} />
          <Route path="admin/new-customer" element={<AdminCustomerForm />} />
          <Route path="admin/payments" element={<AdminPaymentsTable />} />
          <Route path="docs" element={<DocsTable />} />
          <Route path="profile" element={<MyProfilePage />} />
          <Route path="support" element={<SupportTable />} />
          <Route path="lgpd" element={<LGPDPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
