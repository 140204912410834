const TitleStripe = ({ children, className }) => {
  return (
    <div
      className={`shadow-md px-2 py-2 bg-primary text-white rounded-md font-semibold mt-5 ${className}`}
    >
      {children}
    </div>
  );
};

export default TitleStripe;
