import React, { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import { USERS } from "../../config/apiConfig";
import { useQuery } from "react-query";
import axios from "../../config/axiosInstance";
import { AiOutlineFileSearch } from "react-icons/ai";
import DrawerLoading from "../DrawerLoading";
import ForbiddenQueriesPermission from "../common/ForbiddenQueriesPermission";
import SerasaBasicoQuery2 from "../queries/SerasaBasicoQuery2";
import CrednetLightQuery2 from "../queries/CrednetLightQuery2";
import SCPCNetCadastralQuery from "../queries/SCPCNetCadastralQuery";

const QueriesEHM2Table = () => {
  const [selectedQuery, setSelectedQuery] = useState("");
  const [selectedPrice, setSelectedPrice] = useState(0);

  const {
    isLoading,
    error,
    data: userQueryPermissions,
  } = useQuery(["userQueryPermissions"], async () => {
    const { data: items } = await axios.get(
      `${USERS.GET_USER_QUERY_PERMISSIONS}`
    );

    return items.data.filter((query) => query.categoryId === 3);
  });

  useEffect(() => {
    if (selectedQuery) {
      const matchingQuery = userQueryPermissions.find(
        (query) => query.id === selectedQuery
      );

      setSelectedPrice(matchingQuery.price);
    }
  }, [selectedQuery]);

  const queries = [
    {
      id: 11,
      name: "Serasa Relatório Básico",
      component: (
        <SerasaBasicoQuery2 queryId={11} selectedPrice={selectedPrice} />
      ),
    },
    {
      id: 12,
      name: "Serasa sem Score | Crednet",
      component: (
        <CrednetLightQuery2 queryId={12} selectedPrice={selectedPrice} />
      ),
    },
    {
      id: 13,
      name: "SCPC Net 3",
      component: (
        <SCPCNetCadastralQuery queryId={13} selectedPrice={selectedPrice} />
      ),
    },
  ];

  return (
    <div className="w-100 flex flex-col lg:flex-row gap-2 relative">
      {isLoading && <DrawerLoading />}
      {userQueryPermissions?.length > 0 ? (
        <>
          <div className="w-full lg:w-2/6 p-4 flex flex-col gap-3">
            <h2 className="title-syncx text-lg">Consultas EHM</h2>
            {userQueryPermissions?.map((query, index) => (
              <Card
                key={index}
                className={`card-query ${
                  selectedQuery === query.id && "active"
                }`}
                onClick={() => setSelectedQuery(query.id)}
              >
                {query.name}
              </Card>
            ))}
          </div>
          {selectedQuery ? (
            <div className={`w-full p-4`}>
              {queries.find((query) => query.id === selectedQuery)?.component}
            </div>
          ) : (
            <div className="mt-14 w-full text-center py-10 border-dashed border-gray-300 border-2 rounded font-semibold text-gray-500 flex items-center justify-center min-h-60 flex gap-1 flex-col">
              <AiOutlineFileSearch fontSize={30} />
              <span>Escolha uma opção ao lado para realizar uma consulta.</span>
            </div>
          )}
        </>
      ) : (
        <ForbiddenQueriesPermission />
      )}
    </div>
  );
};

export default QueriesEHM2Table;
