import React, { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import { USERS } from "../../config/apiConfig";
import { useQuery } from "react-query";
import axios from "../../config/axiosInstance";
import AcertaBvCPFQuery from "../queries/AcertaBvCPFQuery";
import ScrBacenScoreQuery from "../queries/ScrBacenScoreQuery";
import DrawerLoading from "../DrawerLoading";
import { AiOutlineFileSearch } from "react-icons/ai";
import ForbiddenQueriesPermission from "../common/ForbiddenQueriesPermission";
import AcertaBvCNPJQuery from "../queries/AcertaBvCNPJQuery";
import AcertaBVCPFSemProtestoQuery from "../queries/AcertaBVCPFSemProtestoQuery";
import SCPCNetQuery from "../queries/SCPCNetQuery";
import ConsultaSimplesQuery4 from "../queries/ConsultaSimplesQuery4";
import SerasaBasicoQuery4 from "../queries/SerasaBasicoQuery4";
import SpcCPFQuery4 from "../queries/SpcCPFQuery4";
import CrednetLightQuery4 from "../queries/CrednetLightQuery4";

const QueriesConsultPositivoTable2 = () => {
  const [selectedQuery, setSelectedQuery] = useState("");
  const [selectedPrice, setSelectedPrice] = useState(0);

  const {
    isLoading,
    error,
    data: userQueryPermissions,
  } = useQuery(["userQueryPermissions"], async () => {
    const { data: items } = await axios.get(
      `${USERS.GET_USER_QUERY_PERMISSIONS}`
    );

    return items.data.filter((query) => query.categoryId === 4);
  });

  useEffect(() => {
    if (selectedQuery) {
      const matchingQuery = userQueryPermissions.find(
        (query) => query.id === selectedQuery
      );

      setSelectedPrice(matchingQuery.price);
    }
  }, [selectedQuery]);

  const queries = [
    {
      id: 14,
      name: "Consulta Simples 4",
      component: (
        <ConsultaSimplesQuery4 queryId={14} selectedPrice={selectedPrice} />
      ),
    },
    {
      id: 15,
      name: "Serasa Básico 4",
      component: (
        <SerasaBasicoQuery4 queryId={15} selectedPrice={selectedPrice} />
      ),
    },
    {
      id: 16,
      name: "SPC Brasil 4",
      component: <SpcCPFQuery4 queryId={16} selectedPrice={selectedPrice} />,
    },
    {
      id: 17,
      name: "Serasa sem score | crednet - 4",
      component: (
        <CrednetLightQuery4 queryId={17} selectedPrice={selectedPrice} />
      ),
    },
  ];

  return (
    <div className="w-100 flex flex-col lg:flex-row gap-2 relative">
      {isLoading && <DrawerLoading />}
      {userQueryPermissions?.length > 0 ? (
        <>
          <div className="w-full lg:w-3/12 p-4 flex flex-col gap-3">
            <h2 className="title-syncx text-lg">Consultas Consult</h2>
            {userQueryPermissions?.map((query, index) => (
              <Card
                key={index}
                className={`card-query ${
                  selectedQuery === query.id && "active"
                }`}
                onClick={() => setSelectedQuery(query.id)}
              >
                {query.name}
              </Card>
            ))}
          </div>
          {selectedQuery ? (
            <div className={`w-full lg:w-9/12 p-4`}>
              {queries.find((query) => query.id === selectedQuery)?.component}
            </div>
          ) : (
            <div className="mt-14 w-full lg:w-9/12 text-center py-10 border-dashed border-gray-300 border-2 rounded font-semibold text-gray-500 flex items-center justify-center min-h-60 flex gap-1 flex-col">
              <AiOutlineFileSearch fontSize={30} />
              <span>Escolha uma opção ao lado para realizar uma consulta.</span>
            </div>
          )}
        </>
      ) : (
        <ForbiddenQueriesPermission />
      )}
    </div>
  );
};

export default QueriesConsultPositivoTable2;
