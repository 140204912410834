import React, { useState } from "react";
import { Alert, Badge, Button, Card, Timeline } from "flowbite-react";
import ReactApexChart from "react-apexcharts";
import { QUERIES } from "../config/apiConfig";
import { useQuery } from "react-query";
import axios from "../config/axiosInstance";
import moment from "moment";
import { Link } from "react-router-dom";
import { formatToReal } from "../utils/utils";
import DrawerLoading from "./DrawerLoading";
import { MdOutlineManageSearch } from "react-icons/md";

const Dashboard = ({ children }) => {
  const {
    isLoading,
    error: errorDashboardInfos,
    data: dashboardInfos,
  } = useQuery(["dashboardInfos"], async () => {
    const { data } = await axios.get(`${QUERIES.GET_DASHBOARD_INFOS}`);

    return data.data;
  });

  return (
    <>
      <div className="rounded-lg dark:border-gray-700 mt-3">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4 mb-4">
          <Card className="card-dashboard relative">
            <div className="title-syncx">Média de consultas por horários</div>
            {dashboardInfos?.averageQueriesByPeriod && (
              <ReactApexChart
                options={{
                  chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                      enabled: false,
                    },
                    toolbar: {
                      show: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "straight",
                  },
                  grid: {
                    row: {
                      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                      opacity: 0.5,
                    },
                  },
                  xaxis: {
                    categories: ["00h-06h", "06h-12h", "12h-18h", "18h-24h"],
                  },
                }}
                series={[
                  {
                    name: "Média de consultas",
                    data: Object.values(dashboardInfos?.averageQueriesByPeriod),
                  },
                ]}
                type="line"
                height={350}
              />
            )}

            {isLoading && <DrawerLoading />}
          </Card>
          <Card className="card-dashboard relative">
            <div className="title-syncx">Histórico de consultas</div>
            {!isLoading && dashboardInfos?.lastQueriesBy7Days?.length > 0 && (
              <ReactApexChart
                options={{
                  chart: {
                    height: 350,
                    type: "bar",
                    events: {
                      click: function (chart, w, e) {
                        // console.log(chart, w, e)
                      },
                    },
                    toolbar: {
                      show: false,
                    },
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "45%",
                      distributed: true,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                  },
                  xaxis: {
                    categories: dashboardInfos?.lastQueriesBy7Days?.map(
                      (obj) => {
                        const [, month, day] = obj.date.split("-");
                        return `${day}/${month}`;
                      }
                    ),
                    labels: {
                      style: {
                        fontSize: "12px",
                      },
                    },
                  },
                }}
                series={[
                  {
                    name: "Qtd. de consultas",
                    data: dashboardInfos?.lastQueriesBy7Days?.map(
                      (obj) => obj.totalQueries
                    ),
                  },
                ]}
                type="bar"
                height={350}
              />
            )}

            {isLoading && <DrawerLoading />}
          </Card>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-4">
          <Card className="card-dashboard relative">
            <div className="flex justify-between items-center">
              <div className="title-syncx">Últimas consultas</div>
              <Button size="xs">
                <Link to="/queries/details">Ver detalhes</Link>
              </Button>
            </div>
            {!isLoading && (
              <>
                {dashboardInfos?.lastQueries?.length > 0 ? (
                  <Timeline>
                    {dashboardInfos?.lastQueries?.map((query, index) => (
                      <Timeline.Item key={index} className="mb-2">
                        <Timeline.Point />
                        <Timeline.Content>
                          <Timeline.Time>
                            {moment(query.createdAt).format("DD/MM/YY - HH:mm")}
                            h
                          </Timeline.Time>
                          <Timeline.Title className="text-base">
                            Consulta: {query.queryName}
                          </Timeline.Title>
                          <Timeline.Body className="text-sm mb-0">
                            Usuário: {query.userName}
                          </Timeline.Body>
                        </Timeline.Content>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                ) : (
                  <div className="text-center my-20 font-semibold flex flex-col gap-2 items-center text-gray-700">
                    <MdOutlineManageSearch
                      fontSize={26}
                      style={{ color: "var(--primary)" }}
                    />
                    <div>Nenhuma consulta realizada</div>
                  </div>
                )}
              </>
            )}
            {isLoading && <DrawerLoading />}
          </Card>
          <Card className="card-dashboard relative">
            <div className="flex items-center justify-between">
              <div className="title-syncx">Produtos mais consultados / mês</div>
            </div>
            <div className="flow-root">
              {dashboardInfos?.mostSearchedProductsMonth?.length > 0 ? (
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                  {dashboardInfos?.mostSearchedProductsMonth?.map(
                    (query, index) => (
                      <li key={index} className="pb-0 pt-3 pb-3">
                        <div className="flex items-center space-x-4">
                          <div className="min-w-0 flex-1">
                            <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                              {query.name}
                            </p>
                          </div>
                          <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                            {query.totalQueries}
                          </div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <div className="text-center my-20 font-semibold flex flex-col gap-2 items-center text-gray-700">
                  <MdOutlineManageSearch
                    fontSize={26}
                    style={{ color: "var(--primary)" }}
                  />
                  <div>Nenhum produto consultado</div>
                </div>
              )}
            </div>
            {isLoading && <DrawerLoading />}
          </Card>
          <Card className="card-dashboard relative">
            <div className="title-syncx">Total de consultas / mês</div>
            <div className="flex gap-2 flex-col">
              <div className="flex flex-col gap-1">
                <span className="text-base font-semibold">
                  Total de consultas até o momento:
                </span>
                <Badge className="w-fit shadow" size="lg" color="green">
                  {dashboardInfos?.totalCurrentMonth?.totalQueries || 0}
                </Badge>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-base font-semibold">
                  Total de consultas previstas:
                </span>
                <Badge className="w-fit shadow" size="lg" color="gray">
                  {dashboardInfos?.totalCurrentMonth?.estimatedQueries}
                </Badge>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-base font-semibold">Média diária:</span>
                <Badge className="w-fit shadow" size="lg" color="gray">
                  {Math.ceil(dashboardInfos?.totalCurrentMonth?.averageDaily)}
                </Badge>
              </div>
            </div>
            <Alert color="green">
              A previsão é baseada na quantidade média de consultas diárias
            </Alert>
            {isLoading && <DrawerLoading />}
          </Card>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
